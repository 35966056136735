import { useStorage } from '@vueuse/core';

export function useToken() {
  const token = useStorage('token', '');
  const refresh_token = useStorage('refresh_token', '');
  const token_expires_at = useStorage('token_expires_at', '');

  function setToken(data: {
    token: string;
    refresh_token: string;
    token_expires_at: string;
  }) {
    token.value = data.token;
    refresh_token.value = data.refresh_token;
    token_expires_at.value = data.token_expires_at.replace('.000000Z', '.000Z');
  }

  return {
    setToken,
    token,
  };
}
